<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Фид'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'Загрузить Фид'"
      :title="'Загрузить Фид'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Submit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Формат</p>
          <div class="input-container">
            <MainSelect
              :id="'format'"
              :placeholder="'Choose'"
              :option-list="formatFeedList"
              :set-value="formDataToSend"
              :value="formDataToSend.format"
              :error-messages="validationErrors['format']"
            />
          </div>

          <!-- <p class="form-create__label">Файл Фида</p>
          <div class="input-container upload-instruction">
            <input @change="handleLoadFile" type="file" name="file" id="file" accept="*" />
            <label v-if="formDataToSend.file == null" class="upload-instruction__button" for="file"
              >Load</label
            >
            <label v-else class="upload-instruction__button" for="instruction">Change</label>
          </div> -->
          <!-- <Notification :error-messages="validationErrors['file']" /> -->
          <p class="form-create__label">Link</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'link'"
              :label="'link'"
              :type="'link'"
              :placeholder="'Link'"
              :error-messages="validationErrors['link']"
              isBordered
            />
          </div>

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Submit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import feedApi from "~/api/feed";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
import MainSelect from "~/components/atoms/inputs/MainSelect.vue";
// import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";
// import Notification from "~/components/molecules/Notification.vue";

export default {
  name: "FeedCreate",
  metaInfo: {
    title: "Загрузить Фид",
  },
  data() {
    return {
      formDataToSend: {
        format: null,
        // file: null,
        link: "",
      },
      formatFeedList: [],
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    MainSelect,
    // MainCheckbox,
    // Notification,
  },
  mounted() {
    this.getFeedFormat();
  },
  computed: {
    ...mapState("feed", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.FEED_LIST.replace(":page", 1);
    },
  },
  methods: {
    handleSubmit() {
      // this.formDataToSend.file = document.querySelector(`input[name="file"]`).files[0];
      let data = new FormData();
      // if (this.formDataToSend.file) {
      //   data.append("file", this.formDataToSend.file);
      // }
      if (this.formDataToSend.link) {
        data.append("link", this.formDataToSend.link);
      }
      if (this.formDataToSend.format.id) {
        data.append("format_id", this.formDataToSend.format.id);
      }
      this.$store.dispatch("feed/createFeed", data);
    },
    handleLoadFile() {
      this.formDataToSend.file = document.querySelector(`input[name="file"]`).files[0];
    },
    getFeedFormat() {
      this.$store.commit("feed/setLoading", true);
      feedApi
        .getFeedFormats()
        .then((res) => {
          this.formatFeedList = res.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.commit("feed/setLoading", false);
        });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.FEED_LIST.replace(":page", 1),
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
